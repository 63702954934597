import { Space, Table, Button, Modal, Card, DatePicker, Divider, Input, List, Progress, Slider, Spin, Switch, notification, Select } from "antd";
import {  Link, useLocation  } from "react-router-dom";

import React, { useState, useEffect, useCallback } from "react";
import { ethers } from "ethers";
import SafeServiceClient from '@gnosis.pm/safe-service-client'
import { Address, Balance, EtherInput, AddressInput, TokenBalance } from "../components";
import { usePoller, useLocalStorage, useBalance, useSafeSdk, useContractReader } from "../hooks";
import { EthSignSignature } from './EthSignSignature'
import WalletConnect from "@walletconnect/client";
import TeamABI from '../abi/Team'
import PrivateABI from '../abi/Private'
import CommunityABI from '../abi/Community'
import { NETWORK } from "../constants";
import LogRocket from 'logrocket';

function dateFormat  (date, fmt) {
  var o = {
    'M+': date.getMonth() + 1, // month
    'd+': date.getDate(), // day
    'h+': date.getHours(), // hour
    'm+': date.getMinutes(), // min
    's+': date.getSeconds(), // sec
    'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
    S: date.getMilliseconds(), // ms
    W: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'][date.getDay()]
  }
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (var k in o) { if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
  return fmt
}


export default function GnosisStarterView({
                                            userSigner,
                                            address,
                                            mainnetProvider,
                                            readContracts,
                                            writeContracts,
                                            localProvider,
                                            price,
                                            blockExplorer,
                                            isNetworkCorrect,
                                            selectedChainId,
                                            targetNetwork
                                          }) {
  let safeServiceUrl = 'https://safe-transaction.rinkeby.gnosis.io'
  if (process.env.REACT_APP_TARGET_NETWORK_KEY === 'bsc') {
    safeServiceUrl = 'https://safe-transaction.bsc.gnosis.io'
  }
  const serviceClient = new SafeServiceClient(safeServiceUrl);

  const [to, setTo] = useState('')
  const [threshold, setThreshold] = useState(0)
  const [owners, setOwners] = useState([])
  const [transactions, setTransactions] = useState([])
  const [value, setValue] = useState(0)
  const [selector, setSelector] = useState('')
  const [unlockFunctionName, setUnlockFunctionName] = useState('')
  const [lockTokenAmount, setLockTokenAmount] = useState('')
  const [vestingDurationInDays, setVestingDurationInDays] = useState('')
  const [vestingCliffInDays, setVestingCliffInDays] = useState('')
  const [startVestingAmount, setStartVestingAmount] = useState(0)
  const [params, setParams] = useState([])
  const [data, setData] = useState('0x00')

  // const OWNERS = [
  //   "0xC49a2AcAFfb955e6c9894fFcc408884eea3074E6",
  //   "0xf370a52afB596b4CDFdc976F3417b84551Ce1dd6"
  // ]
  // const THRESHOLD = 2
  useEffect(() => {
    LogRocket.identify(address);
    console.log('logrocket', address);
  }, [address])

  const [safeAddress, setSafeAddress] = useLocalStorage("deployedSafe")
  const [ deploying, setDeploying ] = useState()
  const safeBalance = useBalance(localProvider, safeAddress);
  const { safeSdk, safeFactory } = useSafeSdk(userSigner, safeAddress)

  const isSafeOwnerConnected = owners.includes(address)

  const deploySafe = useCallback(async (owners, threshold) => {
    if (!safeFactory) return
    setDeploying(true)
    const safeAccountConfig = { owners, threshold }
    let safe
    try {
      safe = await safeFactory.deploySafe(safeAccountConfig)
    } catch (error) {
      console.error(error)
      setDeploying(false)
      return
    }
    const newSafeAddress = ethers.utils.getAddress(safe.getAddress())
    setSafeAddress(newSafeAddress)
  }, [safeFactory])

  const parseEther = cb => {
    return v => {
      v = v && v.toString && v.toString()
      if(v){
        const valueResult = ethers.utils.parseEther(""+v.replace(/\D/g, ""))
        cb(valueResult);
      } else {
        cb('0')
      }
    }
  }
  const proposeSafeTransaction = useCallback(async (transaction) => {
    if (!safeSdk || !serviceClient) return
    let safeTransaction
    try {
      safeTransaction = await safeSdk.createTransaction(transaction)
    } catch (error) {
      console.log('proposeSafeTransaction', safeTransaction, error)
      Modal.error({
        title: "🛑 Error Proposing Transaction",
        content:  `${error.toString()} (check console)`,
      });

      return
    }
    console.log('SAFE TX', safeTransaction)
    const safeTxHash = await safeSdk.getTransactionHash(safeTransaction)
    console.log('HASH', safeTxHash)
    const safeSignature = await safeSdk.signTransactionHash(safeTxHash)
    try {

      await serviceClient.proposeTransaction({
        safeAddress,
        safeTransaction,
        safeTxHash,
        senderAddress: address,
      })
    } catch (e) {
      console.log('error', e);
    }

  }, [safeSdk, serviceClient, safeAddress])

  const confirmTransaction = useCallback(async (transaction) => {
    if (!safeSdk || !serviceClient) return
    const hash = transaction.safeTxHash
    let signature
    try {
      signature = await safeSdk.signTransactionHash(hash)
    } catch (error) {
      console.error(error)
      return
    }
    await serviceClient.confirmTransaction(hash, signature.data)
  }, [safeSdk, serviceClient])

  const executeSafeTransaction = useCallback(async (transaction) => {
    if (!safeSdk) return
    console.log(transaction)
    const safeTransactionData = {
      to: transaction.to,
      value: transaction.value,
      data: transaction.data || '0x',
      operation: transaction.operation,
      safeTxGas: transaction.safeTxGas,
      baseGas: transaction.baseGas,
      gasPrice: Number(transaction.gasPrice),
      gasToken: transaction.gasToken,
      refundReceiver: transaction.refundReceiver,
      nonce: transaction.nonce
    }
    const safeTransaction = await safeSdk.createTransaction(safeTransactionData)
    transaction.confirmations.forEach(confirmation => {
      const signature = new EthSignSignature(confirmation.owner, confirmation.signature)
      safeTransaction.addSignature(signature)
    })
    let executeTxResponse
    try {
      executeTxResponse = await safeSdk.executeTransaction(safeTransaction)
    } catch(error) {
      console.error(error)
      return
    }
    const receipt = executeTxResponse.transactionResponse && (await executeTxResponse.transactionResponse.wait())
    console.log(receipt)
  }, [safeSdk])

  const isTransactionExecutable = (transaction) => transaction.confirmations.length >= threshold

  const isTransactionSignedByAddress = (transaction) => {
    const confirmation = transaction.confirmations.find(confirmation => confirmation.owner === address)
    return !!confirmation
  }


  // poll safe owner list and threshold
  usePoller(async () => {
    if(safeAddress){
      setSafeAddress(ethers.utils.getAddress(safeAddress))
      try{
        if(safeSdk){
          const owners = await safeSdk.getOwners()
          const threshold = await safeSdk.getThreshold()
          setOwners(owners)
          setThreshold(threshold)
          // console.log("owners",owners,"threshold",threshold)
        }
        // console.log("CHECKING TRANSACTIONS....",safeAddress)
        const serviceInfo = await serviceClient.getServiceInfo()
        console.log({serviceInfo, safeServiceUrl});
        const transactions = await serviceClient.getPendingTransactions(safeAddress)
        // console.log("Pending transactions:", transactions)
        setTransactions(transactions.results)
      }catch(e){
        console.log("ERROR POLLING FROM SAFE:",e)
      }
    }
  }, 3333);

  const [ walletConnectUrl, setWalletConnectUrl ] = useState()
  const [ connected, setConnected ] = useState()


  let showForm = false


  const [isInvester, setisInvester] = useState('')
  const [tokenDRF, setTokenDRF] = useLocalStorage("tokenDRF")
  const [teamVestingVaultAddr, setTeamVestingVaultAddr] = useLocalStorage("teamVestingVaultAddr")
  const [communityVestingVaultAddr, setCommunityVestingVaultAddr] = useLocalStorage("communityVestingVaultAddr")
  const [privateVestingVaultAddr, setPrivateVestingVaultAddr] = useLocalStorage("privateVestingVaultAddr")
  if (
    isNetworkCorrect
    && tokenDRF
    && teamVestingVaultAddr
    && communityVestingVaultAddr
    && privateVestingVaultAddr
  ) {
    showForm = true
  }

  const currentNetworkName = NETWORK(selectedChainId) ? NETWORK(selectedChainId).name : 'unknown'


  let safeInfo
  if(safeAddress && isNetworkCorrect){
    safeInfo = (
      <div>
        <h2 style={{color: 'red'}}>Network: {currentNetworkName}</h2>
        <Address value={safeAddress} ensProvider={mainnetProvider} blockExplorer={blockExplorer} />

        <div style={{padding:4}}>
          {owners&&owners.length>0?(
            <>
              <b>Signers:</b>
              <List
                bordered
                dataSource={owners}
                renderItem={item => {
                  return (
                    <List.Item key={item + "_ownerEntry"}>
                      <Address address={item} ensProvider={mainnetProvider} fontSize={12} />
                    </List.Item>
                  );
                }}
              />
            </>
          ):<Spin/>}

        </div>
      </div>
    )
  } else {
    if (!isNetworkCorrect) {
      safeInfo = (
        <div>
          <h2 style={{color: 'red'}}>Network: {currentNetworkName}</h2>
          <h2 style={{color: 'red'}}>Wrong Network!!</h2>
        </div>
      )
    } else {
      safeInfo = (
        <div style={{padding:4}}>
          {/* <Button loading={deploying} onClick={() => deploySafe(OWNERS, THRESHOLD)} type={"primary"} >
          DEPLOY SAFE
        </Button> */}
          <div> enter your safe address: </div>
          <AddressInput ensProvider={mainnetProvider} onChange={(addr)=>{
            if(ethers.utils.isAddress(addr)){
              console.log("addr!",addr)

              setSafeAddress(ethers.utils.getAddress(addr))
            }
          }}/>
        </div>
      )
    }
  }


  // const contractConfig = {
  //   tokenDRF:                  '0x6ACA58B3972c1B8FF080E83a436C06a3C72c70b4', //t8-rinkeby
  //   teamVestingVaultAddr:      '0x68dD9465E71eCcCf307a69511415d982Cbb0DdBE',
  //   communityVestingVaultAddr: '0x2C2F86603cD009d3De1a150720B515948eC8BAbb',
  //   privateVestingVaultAddr:   '0xA012D207DaaF063B0cA02d26c89dEE4635d5dbe8',
  // }

  let contractConfigInfo = (
    <div>
      <div style={{ padding: 4 }}>
        tokenDRF Address：
        <AddressInput placeholder="Enter tokenDRF Address"
                      onChange={setTokenDRF}
                      ensProvider={mainnetProvider}
                      value={tokenDRF}
        />
      </div>
      <div style={{ padding: 4 }}>
        teamVestingVaultAddr Address：
        <AddressInput placeholder="Enter teamVestingVaultAddr Address"
                      onChange={setTeamVestingVaultAddr}
                      ensProvider={mainnetProvider}
                      value={teamVestingVaultAddr}
        />
      </div>
      <div style={{ padding: 4 }}>
        communityVestingVaultAddr Address：
        <AddressInput placeholder="Enter communityVestingVaultAddr Address"
                      onChange={setCommunityVestingVaultAddr}
                      ensProvider={mainnetProvider}
                      value={communityVestingVaultAddr}
        />
      </div>
      <div style={{ padding: 4 }}>
        privateVestingVaultAddr Address：
        <AddressInput placeholder="Enter privateVestingVaultAddr Address"
                      onChange={setPrivateVestingVaultAddr}
                      ensProvider={mainnetProvider}
                      value={privateVestingVaultAddr}
        />
      </div>
    </div>
  )

  if (!isNetworkCorrect) {
    contractConfigInfo = ''
  }

  const [theAction, setTheAction] = useLocalStorage("TheAction")
  const onActionChange = value => {
    setTheAction(value);
  };
  const [vestingContractAddress, setVestingContractAddress] = useLocalStorage("vestingContractAddress")
  const [theType, setTheType] = useLocalStorage("theType")
  const onTypeChange = value => {
    setTheType(value);
  };
  const getVestingContractAddress = () => {
    let val = '';
    switch (theType) {
      case 'team':
        val = teamVestingVaultAddr;
        break;
      case 'community':
        val = communityVestingVaultAddr;
        break;
      case 'private':
        val = privateVestingVaultAddr;
        break;
    }
    return val;
  }
  const getVestingContractABI = () => {
    let val = '';
    switch (theType) {
      case 'team':
        val = TeamABI;
        break;
      case 'community':
        val = CommunityABI;
        break;
      case 'private':
        val = PrivateABI;
        break;
    }
    return val;
  }
  useEffect(() => {
    const address = getVestingContractAddress();
    setVestingContractAddress(address);
  }, [theType]);

  let { search } = useLocation();
  useEffect(() => {
    if (!search) return;

    const query = new URLSearchParams(search);
    if (query.get('isInvester') === 'yes') {
      setisInvester('yes')
    }
    const drf = query.get('drf');
    drf && setTokenDRF(drf)
    const tvv = query.get('tvv');
    tvv && setTeamVestingVaultAddr(tvv)
    const cvv = query.get('cvv');
    cvv && setCommunityVestingVaultAddr(cvv)
    const pvv = query.get('pvv');
    pvv && setPrivateVestingVaultAddr(pvv)
    const theType = query.get('theType');
    theType && setTheType(theType)
  }, [search])


  const [ canClaimGrantItems, setCanClaimGrantItems ] = useState()
  const canClaimGrantItemsColumns = [
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      key: 'startTime',
    },
    {
      title: 'daysClaimed',
      dataIndex: 'daysClaimed',
      key: 'daysClaimed',
    },
    {
      title: 'amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'recipient',
      dataIndex: 'recipient',
      key: 'recipient',
    },
    {
      title: 'unclaimed number',
      dataIndex: 'unclaimedNumber',
      key: 'unclaimedNumber',
    },
    {
      title: 'total claim',
      dataIndex: 'totalClaimed',
      key: 'totalClaimed',
    },
    {
      title: 'locked number',
      dataIndex: 'lockedNumber',
      key: 'lockedNumber',
    },
    // {
    //   title: 'vestingDuration',
    //   dataIndex: 'vestingDuration',
    //   key: 'vestingDuration',
    // },


    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={async () => {
            const contract = new ethers.Contract(vestingContractAddress, getVestingContractABI(), userSigner);
            try {
              const rz = await contract.claimVestedTokens();
            } catch (e) {
              console.error('====>>>claimVestedTokens error', e)
              notification.open({
                message: "🛑 Error claim",
                description: (
                  <>
                    {JSON.stringify(e)} (check console)
                  </>
                ),
              });
            }
          }}>Claim</Button>
        </Space>
      ),
    },
  ]
  const updateGrantData = async () => {
    // const vestingContractAddress = '0x9e8e49dd1921e2464014c5a24e82d516b64aea03'
    // const address = '0xa8B5719Ad76518a93828ADa2804d20D6cE4a4C55'
    if (!address || !vestingContractAddress || !isNetworkCorrect) {
      return
    }
    const contract = new ethers.Contract(vestingContractAddress, getVestingContractABI(), localProvider);
    const getGrantData = await contract.getGrant(address);
    console.log('getGrantData', getGrantData);
    if(getGrantData.amount.toString() === '0'){
      return
    }
    const calculateGrantClaimData = await contract.calculateGrantClaim(address);
    const unclaimedNumber = ethers.utils.formatEther(calculateGrantClaimData[1])
    const lockedNumber = ethers.utils.formatEther(getGrantData.amount.sub(getGrantData.totalClaimed).sub(calculateGrantClaimData[1]).toString());
    const items = [
      {
        startTime: dateFormat(new Date(getGrantData.startTime * 1000), 'yyyy-MM-dd'),
        amount: ethers.utils.formatEther(getGrantData.amount.toString()),
        unclaimedNumber,
        lockedNumber,
        vestingDuration: getGrantData.vestingDuration.toString(),
        daysClaimed: getGrantData.daysClaimed.toString(),
        totalClaimed: ethers.utils.formatEther(getGrantData.totalClaimed.toString()),
        recipient: getGrantData.recipient.toString(),
      }
    ]
    setCanClaimGrantItems(items)
  }
  useEffect(() => {
    if (address && localProvider && theType) updateGrantData();
  }, [address, localProvider, theType]);

  const theActionFormMap = {
    'transaction': (
      <div>
        <h5>Propose Transaction: </h5>
        <div style={{ margin: 8}}>
          <div style={{ padding: 4 }}>
            <AddressInput placeholder="Enter To Address"
                          onChange={setTo}
                          ensProvider={mainnetProvider}
                          value={to}
            />
          </div>
          <div style={{ padding: 4 }}>
            <EtherInput
              autofocus
              placeholder="Enter Tx Value"
              value={value}
              /*
              onChange={v => {
                v = v && v.toString && v.toString()
                if(v){
                  const valueResult = ethers.utils.parseEther(""+v.replace(/\D/g, ""))
                  setValue(valueResult);
                }

              }}*/
              onChange={parseEther(setValue)}
            />
          </div>
          <div style={{ padding: 4 }}>
            <Input placeholder="Enter Selector i.e add(uint, uint)"
                   onChange={async (e) => {
                     setSelector(e.target.value)
                   }}
            />
          </div>
          <div style={{ padding: 4 }}>
            <Input placeholder="Enter arguments separated by ,"
                   onChange={async (e) => {
                     setParams(e.target.value.split(','))
                   }}
            />
          </div>
          {data?data:""}
          <Button
            style={{ marginTop: 8 }}
            onClick={async () => {
              if (selector !== '' && params.length > 0) {
                const abi = [
                  "function " + selector
                ];
                const index = selector.indexOf('(');
                const fragment = selector.substring(0, index)

                const iface = new ethers.utils.Interface(abi);
                for (let i = 0; i < params.length; i++) {
                  if (iface.fragments[0].inputs[i].baseType.includes('uint') || iface.fragments[0].inputs[i].baseType.includes('int')) {
                    params[i] = parseInt(params[i])
                  }
                }
                const data = iface.encodeFunctionData(fragment, params);
                setData(data)
              }

              const checksumForm = ethers.utils.getAddress(to)
              console.log(value);
              const partialTx = {
                to: checksumForm,
                data,
                value,
              }
              try{
                await proposeSafeTransaction(partialTx)
              }catch(e){
                console.log("🛑 Error Proposing Transaction",e)
                notification.open({
                  message: "🛑 Error Proposing Transaction",
                  description: (
                    <>
                      {e.toString()} (check console)
                    </>
                  ),
                });
              }

            }}
          >
            Sign Transaction
          </Button>
        </div>
      </div>
    ),
    'approve': (
      <div >
        <h5>Propose {theType} approve:</h5>
        <div style={{ margin: 8 }}>
          <div style={{ padding: 4 }}>
            <Input placeholder=""
                   onChange={async (e) => {
                     setLockTokenAmount(e.target.value)
                   }}
            />
          </div>
          <Button
            style={{ marginTop: 8 }}
            onClick={async () => {
              const abi = [
                "function approve(address,uint256)"
              ];
              const iface = new ethers.utils.Interface(abi);
              const amount = ethers.utils.parseEther("" + lockTokenAmount.replace(/\D/g, ""));
              console.log(amount.toString());
              const address = vestingContractAddress
              const params = [address, amount];
              const data = iface.encodeFunctionData('approve', params);
              setData(data)

              const checksumForm = ethers.utils.getAddress(tokenDRF)
              console.log(checksumForm);
              const partialTx = {
                to: checksumForm,
                data,
                value: '0'
              }
              console.log(partialTx);
              try{
                await proposeSafeTransaction(partialTx)
              }catch(e){
                console.log("🛑 Error Proposing Transaction",e)
                notification.open({
                  message: "🛑 Error Proposing Transaction",
                  description: (
                    <>
                      {e.toString()} (check console)
                    </>
                  ),
                });
              }

            }}
          >
            Sign Transaction
          </Button>
        </div>
      </div>
    ),
    'unlockFunction lockToken': (
      <div>
        <h5>Propose {theType} unlockFunction lockToken:</h5>
        <div style={{ margin: 8}}>
          {/* <div style={{ padding: 4 }}>
            0-lockToken, 1-addGrant, 2-revokeGrant
            <Input placeholder="Enter fn type"
              onChange={async (e) => {
                setUnlockFunctionName(e.target.value)
              }}
            />
          </div> */}
          <Button
            style={{ marginTop: 8 }}
            onClick={async () => {
              const abi = [
                "function unlockFunction(uint8)"
              ];
              const iface = new ethers.utils.Interface(abi);
              const params = [0];
              const data = iface.encodeFunctionData('unlockFunction', params);
              setData(data)

              const checksumForm = ethers.utils.getAddress(vestingContractAddress)
              console.log(checksumForm);
              const partialTx = {
                to: checksumForm,
                data,
                value: '0'
              }
              console.log(partialTx);
              try{
                await proposeSafeTransaction(partialTx)
              }catch(e){
                console.log("🛑 Error Proposing Transaction",e)
                notification.open({
                  message: "🛑 Error Proposing Transaction",
                  description: (
                    <>
                      {e.toString()} (check console)
                    </>
                  ),
                });
              }

            }}
          >
            Sign Transaction
          </Button>
        </div>
      </div>
    ),
    'lockToken': (
      <div>
        <h5>Propose {theType} lockToken:</h5>
        <div style={{ margin: 8}}>
          <div style={{ padding: 4 }}>
            <Input placeholder=""
                   onChange={async (e) => {
                     setLockTokenAmount(e.target.value)
                   }}
            />
          </div>
          <Button
            style={{ marginTop: 8 }}
            onClick={async () => {
              const abi = [
                "function lockToken(uint256)"
              ];
              const iface = new ethers.utils.Interface(abi);
              const amount = ethers.utils.parseEther("" + lockTokenAmount.replace(/\D/g, ""));
              console.log('lockToken', amount.toString());
              const params = [amount];
              const data = iface.encodeFunctionData('lockToken', params);
              setData(data)

              const checksumForm = ethers.utils.getAddress(vestingContractAddress)
              console.log(checksumForm);
              const partialTx = {
                to: checksumForm,
                data,
                value: '0'
              }
              console.log(partialTx);
              try{
                await proposeSafeTransaction(partialTx)
              }catch(e){
                console.log("🛑 Error Proposing Transaction",e)
                notification.open({
                  message: "🛑 Error Proposing Transaction",
                  description: (
                    <>
                      {e.toString()} (check console)
                    </>
                  ),
                });
              }

            }}
          >
            Sign Transaction
          </Button>
        </div>
      </div>
    ),
    'unlockFunction addGrant': (
      <div>
        <h5>Propose {theType} unlockFunction addGrant:</h5>
        <div style={{ margin: 8}}>
          <Button
            style={{ marginTop: 8 }}
            onClick={async () => {
              const abi = [
                "function unlockFunction(uint8)"
              ];
              const iface = new ethers.utils.Interface(abi);
              const params = [1];
              const data = iface.encodeFunctionData('unlockFunction', params);
              setData(data)

              const checksumForm = ethers.utils.getAddress(vestingContractAddress)
              console.log(checksumForm);
              const partialTx = {
                to: checksumForm,
                data,
                value: '0'
              }
              console.log(partialTx);
              try{
                await proposeSafeTransaction(partialTx)
              }catch(e){
                console.log("🛑 Error Proposing Transaction",e)
                notification.open({
                  message: "🛑 Error Proposing Transaction",
                  description: (
                    <>
                      {e.toString()} (check console)
                    </>
                  ),
                });
              }

            }}
          >
            Sign Transaction
          </Button>
        </div>
      </div>
    ),
    'addGrant': (
      <div>
        <h5>Propose {theType} addGrant:</h5>
        <div style={{ margin: 8 }}>
          <div style={{ padding: 4 }}>
            <AddressInput placeholder="Enter To Address"
                          onChange={setTo}
                          ensProvider={mainnetProvider}
                          value={to}
            />
          </div>
          <div style={{ padding: 4 }}>
            <EtherInput
              autofocus
              placeholder="Enter Tx Value"
              value={value}
              /*
              onChange={v => {
                v = v && v.toString && v.toString()
                if(v){
                  const valueResult = ethers.utils.parseEther(""+v.replace(/\D/g, ""))
                  setValue(valueResult);
                }

              }}*/
              onChange={parseEther(setValue)}
            />
          </div>
          <div style={{ padding: 4, display: theType==='private' ? 'block' : 'none' }}>
            <EtherInput
              autofocus
              placeholder="start vesting amount"
              value={startVestingAmount}
              onChange={parseEther(setStartVestingAmount)}
            />
          </div>
          <div style={{ padding: 4 }}>
            vesting duration in days
            <Input placeholder=""
                   onChange={async (e) => {
                     setVestingDurationInDays(e.target.value)
                   }}
            />
          </div>
          <div style={{ padding: 4 }}>
            vesting cliff in days
            <Input placeholder=""
                   onChange={async (e) => {
                     setVestingCliffInDays(e.target.value)
                   }}
            />
          </div>
          <Button
            style={{ marginTop: 8 }}
            onClick={async () => {
              let abi = [
                "function addGrant(address,uint256,uint16,uint16)"
              ];
              if (theType === 'private') {
                abi = [
                  "function addGrant(address,uint256,uint256,uint16,uint16)"
                ]
              }
              const iface = new ethers.utils.Interface(abi);
              const recipient = to;
              const amount = value;

              console.log(value.toString(),startVestingAmount.toString() );
              let params = [recipient, value, vestingDurationInDays, vestingCliffInDays];
              if (theType === 'private') {
                params = [recipient, value, startVestingAmount, vestingDurationInDays, vestingCliffInDays];
              }
              const data = iface.encodeFunctionData('addGrant', params);
              setData(data)
              console.log('addGrant', params)
              const checksumForm = ethers.utils.getAddress(vestingContractAddress)
              const partialTx = {
                to: checksumForm,
                data,
                value: '0'
              }
              console.log(partialTx);
              try{
                await proposeSafeTransaction(partialTx)
              }catch(e){
                console.log("🛑 Error Proposing Transaction",e)
                notification.open({
                  message: "🛑 Error Proposing Transaction",
                  description: (
                    <>
                      {e.toString()} (check console)
                    </>
                  ),
                });
              }

            }}
          >
            Sign Transaction
          </Button>
        </div>
      </div>
    ),
    'unlockFunction revokeGrant': (
      <div>
        <h5>Propose {theType} unlockFunction revokeGrant:</h5>
        <div style={{ margin: 8}}>
          <Button
            style={{ marginTop: 8 }}
            onClick={async () => {
              const abi = [
                "function unlockFunction(uint8)"
              ];
              const iface = new ethers.utils.Interface(abi);
              const params = [2];
              const data = iface.encodeFunctionData('unlockFunction', params);
              setData(data)

              const checksumForm = ethers.utils.getAddress(vestingContractAddress)
              const partialTx = {
                to: checksumForm,
                data,
                value: '0'
              }
              console.log(partialTx);
              try{
                await proposeSafeTransaction(partialTx)
              }catch(e){
                console.log("🛑 Error Proposing Transaction",e)
                notification.open({
                  message: "🛑 Error Proposing Transaction",
                  description: (
                    <>
                      {e.toString()} (check console)
                    </>
                  ),
                });
              }

            }}
          >
            Sign Transaction
          </Button>
        </div>
      </div>
    ),
    'revokeGrant': (
      <div>
        <h5>Propose {theType} revokeGrant:</h5>
        <div style={{ margin: 8 }}>
          <div style={{ padding: 4 }}>
            <AddressInput placeholder="Enter To Address"
                          onChange={setTo}
                          ensProvider={mainnetProvider}
                          value={to}
            />
          </div>
          <Button
            style={{ marginTop: 8 }}
            onClick={async () => {
              const abi = [
                "function revokeGrant(address)"
              ];
              const iface = new ethers.utils.Interface(abi);
              const recipient = to;

              const params = [recipient];
              const data = iface.encodeFunctionData('revokeGrant', params);
              setData(data)

              const checksumForm = ethers.utils.getAddress(vestingContractAddress)
              const partialTx = {
                to: checksumForm,
                data,
                value: '0'
              }
              console.log(partialTx);
              try{
                await proposeSafeTransaction(partialTx)
              }catch(e){
                console.log("🛑 Error Proposing Transaction",e)
                notification.open({
                  message: "🛑 Error Proposing Transaction",
                  description: (
                    <>
                      {e.toString()} (check console)
                    </>
                  ),
                });
              }

            }}
          >
            Sign Transaction
          </Button>
        </div>
      </div>
    ),
  }

  let proposeTransaction
  if(!safeAddress){
    proposeTransaction = ""
  } else if(!owners || owners.length<=0){
    proposeTransaction = ""
  }else if(owners.includes(address)){
    proposeTransaction = (
      <>
        <Divider />

        {/* {connected?"✅":""}<Input
          style={{width:"70%"}}
          placeholder={"wallet connect url"}
          value={walletConnectUrl}
          disabled={connected}
          onChange={(e)=>{
            setWalletConnectUrl(e.target.value)
          }}
        />{connected?<span onClick={()=>{setConnected(false);}}>X</span>:""} */}

        <Divider />
        <h3>2. setup Operation Type and Action:</h3>
        <div style={{ margin: 8 }}>
          <div style={{ padding: 4 }}>
            vesting type:
            <Select placeholder="Select a type" onChange={onTypeChange} value={theType} style={{marginRight: 40}}>
              <Option value="team">team</Option>
              <Option value="community">community</Option>
              <Option value="private">private</Option>
            </Select>
            sign action:
            <Select placeholder="Select a action" onChange={onActionChange} value={theAction}  style={{width: 400}} >
              <Option value="transaction">transaction</Option>
              <Option value="approve">1. approve</Option>
              <Option value="unlockFunction lockToken">2. unlockFunction lockToken</Option>
              <Option value="lockToken">3. lockToken</Option>
              <Option value="unlockFunction addGrant">4. unlockFunction addGrant</Option>
              <Option value="addGrant">5. addGrant</Option>
              <Option value="unlockFunction revokeGrant">6. unlockFunction revokeGrant</Option>
              <Option value="revokeGrant">7. revokeGrant</Option>
            </Select>
          </div>
        </div>
        {/* end block */}

        <Divider />
        {theActionFormMap[theAction] || 'unknown action'}
      </>
    )
  }



  let claimList = (
    <div>
      <h2 style={{ textAlign: 'center', paddingTop: 50,  display: isInvester === 'yes' ? 'none' : 'block' }}>
        <Link to={`/?isInvester=yes&theType=${theType}&drf=${tokenDRF}&tvv=${teamVestingVaultAddr}&cvv=${communityVestingVaultAddr}&pvv=${privateVestingVaultAddr}`} target="_blank">the link to share for invester(click to open new tab and share)</Link>
      </h2>
      <Table dataSource={ canClaimGrantItems } columns={ canClaimGrantItemsColumns } />
    </div>
  )
  if (!isNetworkCorrect) {
    claimList = ''
  }
  return (
    <div>
      <div style={{ border: "1px solid #cccccc", padding: 16, width: 1000, margin: "auto", marginTop: 64, display: isInvester === 'yes' ? 'none' : 'block'}}>
        {safeAddress?<div style={{float:"right", padding:4, cursor:"pointer", fontSize:28}} onClick={()=>{
          setSafeAddress("")
          setTransactions([])
        }}>
          x
        </div>:""}

        <div style={{padding:10}}>
          {safeInfo}
        </div>

        <div style={{padding:10}}>
          {contractConfigInfo}
        </div>

        <div>
          { showForm && proposeTransaction}
        </div>

        <div style={{textAlign: "left"}}>
          <ol>
            <li>connect to your wallet with correct network.</li>
            <li>setup your address.</li>
            <li>select Operation Type and Action</li>
            <li>flow all action order to use those action</li>
          </ol>
        </div>

      </div>
      <Divider />
      <div style={{ margin: 8 }}>
        {
          transactions.length > 0 && transactions.map((transaction) => {

            let buttonDisplay = ""

            if(!owners || owners.length<=0){
              buttonDisplay = (
                <Spin/>
              )
            }else if(!isTransactionExecutable(transaction)){
              if(isSafeOwnerConnected && !isTransactionSignedByAddress(transaction)){
                buttonDisplay = (
                  <Button
                    style={{ marginTop: 8 }}
                    onClick={() => confirmTransaction(transaction)}
                  >
                    Sign TX</Button>
                )
              }else{
                buttonDisplay = "Waiting for more signatures..."
              }
            }else{
              if(isSafeOwnerConnected && isTransactionExecutable(transaction)){
                buttonDisplay = (
                  <Button
                    style={{ marginTop: 8 }}
                    onClick={() => executeSafeTransaction(transaction)}
                  >Execute TX</Button>
                )
              } else {
                buttonDisplay = "Waiting to execute..."
              }
            }


            return (
              <div style={{borderBottom:"1px solid #ddd"}}>
                {/* {console.log("transaction",transaction)} */}
                <h1>#{transaction.nonce}</h1>
                <Address value={transaction.to} ensProvider={mainnetProvider} />
                <p>Data: {transaction.data}</p>
                <p>Value: {ethers.utils.formatEther(transaction.value)} ETH</p>
                <div style={{padding:32}}>
                  {buttonDisplay}
                </div>
              </div>
            )
          })
        }
      </div>
      {claimList}
    </div>
  );
}
